<template>
    <div>
        <div class="container pb-5">
            <SectionTitle title="Publicaciones" /><br />
            <div class="row text-center pt-5 pb-5">
                <div v-for="(publicacion, index) in publicaciones" :key="index" class="col-sm-4 pb-5">
                    <a :href="`pdfs/` + publicacion.pdf" target="blanck">
                        <div class="col">
                            <img
                                class="img-fluid pb-3"
                                :src="require('@/assets/publicaciones/' + publicacion.portada)"
                                alt=""
                            />
                            <p>
                                <strong>{{ publicacion.decripcion }}</strong>
                            </p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            publicaciones: [
                {
                    pdf: "InformeInterinstitucional.pdf",
                    portada: "informe.png",
                    decripcion: "INFORME INTERINSTITUCIONAL",
                },
                {
                    pdf: "EvaluacionEconomicaMoscamed.pdf",
                    portada: "evaluacion.jpg",
                    decripcion: "EVALUACION ECONOMICA IICA",
                },
                {
                    pdf: "MemoriaLaboresMoscamed.pdf",
                    portada: "memoriaLabores.jpg",
                    decripcion: "MEMORIA DE LABORES 2023",
                },
                {
                    pdf: "EstablecimientoCentrosFecundacionAbejasReina.pdf",
                    portada: "establecimientoCentrosFundacionAbejarReina.jpg",
                    decripcion: "Establecimiento de centros de fecundación de Abeja Reina",
                },
                {
                    pdf: "TransporteIntroduccionAbejasReina.pdf",
                    portada: "transporteIntroduccionAbejarReina.jpg",
                    decripcion: "Transporte e introducción de Abeja Reina",
                },
            ],
        };
    },
};
</script>
<style scoped>
a {
    color: black !important;
}
</style>
